import request from '@/utils/request'

export function queryAllDeliveryFee () {
  return request({
    url: '/goods-service/store/listOfPage2',
    method: 'get'
  })
}

export  function updateAllDeliveryFee(data) {
  return request({
    url: 'goods-service/store/supermarketBaseDeliveryFeeConfig',
    method: 'post',
    data: data
  })
}